import { Helmet } from "react-helmet-async";

// sections
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// @mui
import { Box, Container, Stack, AppBar, Toolbar, Typography, CardHeader, CardContent, Card, Button, Tabs, Tab, Grid, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { styled } from "@mui/material/styles";
import VistaActividad from "./VistaActividad";

// routes
// config
import { NAV, HEADER, MAP_API } from "../../config-global";
// components
import Iconify from "../../components/iconify";
import { NavSectionMini, NavSectionVertical, NavSectionHorizontal } from "../../components/nav-section";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";

// routes
import { PATH_PAGE } from "../../routes/paths";
// config
// _mock_
import { cities as CITIES } from "../../_mock/map/cities";
import { routes as COUNTRIES, routes } from "../../_mock/map/countries";
// components
// sections
import _mock from "../../_mock";
import { useSettingsContext } from "src/components/settings";

import { CarouselAnimation, CarouselBasic1, CarouselBasic2, CarouselBasic3, CarouselCenterMode, CarouselThumbnail } from "src/sections/_examples/extra/carousel";
import Image from "src/components/image/Image";
import useResponsive from "../../hooks/useResponsive";
import Markdown from "src/components/markdown";
import DemoMarkdownPage from "../components/extra/DemoMarkdownPage";

// ----------------------------------------------------------------------

const IMG = [_mock.image.cover(2), _mock.image.cover(3), _mock.image.cover(4), _mock.image.cover(5), _mock.image.cover(8)];

export default function PaginaRuta() {
  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const { themeStretch } = useSettingsContext();

  let route = routes.find((route) => route.id == id);

  let { ciudades, apps, alojamiento, otros, destacada, fotos, mapas } = route;

  const [currentTab, setCurrentTab] = useState("one");

  const TABS = [
    {
      value: "one",
      icon: <Iconify icon="eva:star-fill" width={24} />,
      label: "Información Destacada",
    },
    {
      value: "two",
      icon: <Iconify icon="eva:people-fill" width={24} />,
      label: "Actividades",
    },
    {
      value: "three",
      icon: <Iconify icon="eva:home-fill" width={24} />,
      label: "Alojamiento",
    },
    {
      value: "four",
      icon: <Iconify icon="eva:smartphone-fill" width={24} />,
      label: "Apps",
    },
    {
      value: "five",
      icon: <Iconify icon="eva:layers-fill" width={24} />,
      label: "Otros",
    },
  ];

  const _carouselsExample = [...Array(5)].map((_, index) => ({
    id: _mock.id(index),
    title: _mock.text.title(index),
    image: _mock.image.cover(index),
    description: _mock.text.description(index),
  }));

  const render = (tab) => {
    switch (tab) {
      case "one":
        return infoRender(destacada);
      case "two":
        if (!ciudades) return null;
        return activity(ciudades);
      case "three":
        if (!alojamiento) return null;
        return activity(alojamiento);
      case "four":
        if (!apps) return null;
        return activity(apps);
      case "five":
        if (!otros) return null;
        return activity(otros);
      default:
        return null;
    }
  };

  return (
    <Container
      maxWidth={themeStretch ? false : "lg"}
      sx={{
        pt: {
          xs: 6,
          md: 8,
        },
      }}
    >
      <Stack sx={{ px: 0, py: 5 }}>
        <Helmet>
          <title> Route {id} </title>
        </Helmet>

        <Stack spacing={0} sx={{ mb: 0 }}>
          {/* <Typography variant="h6"> Nav Horizontal </Typography> */}

          {/* <Card> */}
          {/* <CardHeader title="Markers & Popups" /> */}
          {/* <CardContent> */}
          {/* <Card> */}
          {/* <CardHeader title="Carousel Center Mode" subheader="Customs shape & icon button" /> */}
          {/* <CardContent> */}
          <CarouselThumbnail
            data={fotos.map((foto, id) => {
              return {
                id: id,
                image: foto,
                title: "",
                description: "",
              };
            })}
          />
          <Stack sx={{ pt: 5 }}>
            <CarouselCenterMode data={mapas} />
          </Stack>

          {/* </CardContent> */}
          {/* </Card> */}
          {/* <Image alt={"Route image"} src={route.photo} ratio="21/9" sx={{ borderRadius: 1.5 }} /> */}

          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography sx={{ py: 5 }} variant="h2">
              {route.nombre}
            </Typography>
            {/* <Button size="large" variant="contained">
              Descargar Ruta
            </Button> */}
          </Stack>

          <Tabs sx={{ pb: 5 }} value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
            {TABS.map((tab) => (
              <Tab key={tab.value} icon={tab.icon} label={tab.label} value={tab.value} disabled={tab.disabled} />
            ))}
          </Tabs>

          <Grid container spacing={2}>
            <Grid item xs={0} sm={0} md={4} lg={4}>
              {/* <NavSectionVertical
                data={NAV_ITEMS}
                sx={{
                  py: 5,
                  borderRadius: 2,
                  bgcolor: "background.paper",
                  boxShadow: (theme) => theme.customShadows.z24,
                }}
              /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                gap={3}
                display="grid"
                // sx={{ p: 25 }}
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                  md: "repeat(1, 1fr)",
                  lg: "repeat(1, 1fr)",
                }}
              >
                {render(currentTab)}
              </Box>
            </Grid>
          </Grid>

          {/* </CardContent> */}
          {/* </Card> */}
        </Stack>
      </Stack>
    </Container>
  );
}

const infoRender = (items) =>
  items.map((info, index) => (
    <Accordion key={info.id}>
      <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
        <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
          <Image alt={""} src={`https://d23scsgq1o4214.cloudfront.net/${info.foto}`} sx={{ borderRadius: 1.5, height: 50, width: 50 }} />
          <Typography variant="subtitle1">{info.titulo}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Markdown children={info.texto} />
      </AccordionDetails>
    </Accordion>
  ));

const activity = (data) => {
  return data.map((info, index) => (
    <Accordion key={info.id}>
      <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
        <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
          <Image alt={""} src={`https://d23scsgq1o4214.cloudfront.net/${info.foto}`} sx={{ borderRadius: 1.5, height: 50, width: 50 }} />
          <Typography variant="h5">{info.ciudad}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {info.actividades.map((actividad, id) => (
            <VistaActividad
              tutorial={{
                name: actividad.titulo,
                description: actividad.descripcion,
                foto: `https://d23scsgq1o4214.cloudfront.net/${actividad.foto}`,
                logo: actividad.logo,
                link: actividad.link,
              }}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  ));
};

const NAV_ITEMS = [
  {
    subheader: "Marketing",
    items: [
      {
        title: "Landing",
        path: "#",
        icon: <Iconify icon="carbon:bat" />,
      },
      {
        title: "Services",
        path: "#",
        icon: <Iconify icon="carbon:cyclist" />,
      },
      {
        title: "Case Studies",
        path: "#",
        icon: <Iconify icon="carbon:3d-cursor-alt" />,
        children: [
          { title: "Case Studies", path: "#" },
          { title: "Case Study", path: "#" },
        ],
      },
      {
        title: "Blog",
        path: "#",
        icon: <Iconify icon="carbon:3d-mpr-toggle" />,
        children: [
          { title: "Blog Posts", path: "#" },
          { title: "Blog Post", path: "#" },
        ],
      },
      {
        title: "About",
        path: "#",
        icon: <Iconify icon="carbon:airport-01" />,
      },
      {
        title: "Contact",
        path: "#",
        icon: <Iconify icon="carbon:battery-full" />,
      },
      {
        title: "Tours",
        path: "#",
        icon: <Iconify icon="carbon:basketball" />,
        children: [
          { title: "Tours", path: "#" },
          { title: "Tour", path: "#" },
        ],
      },
      {
        title: "Checkout",
        path: "#",
        icon: <Iconify icon="carbon:area" />,
        children: [
          { title: "Checkout", path: "#" },
          { title: "Checkout Complete", path: "#" },
        ],
      },
    ],
  },
  {
    subheader: "Travel",
    items: [
      {
        title: "Level 1",
        path: "#",
        icon: <Iconify icon="carbon:play" />,
        children: [
          { title: "Level 2.1", path: "#" },
          { title: "Level 2.2", path: "#" },
          {
            title: "Level 2.3",
            path: "#",
            children: [
              { title: "Level 3.1", path: "#" },
              { title: "Level 3.2", path: "#" },
            ],
          },
        ],
      },
    ],
  },
];
