import PropTypes from "prop-types";
import {useState, memo, useRef, useCallback, useEffect} from "react";
import Map, {Layer, Source, Marker} from "react-map-gl";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import * as turf from "turf";

// @mui
import {Box, Typography, Link} from "@mui/material";
// components
import Image from "../../../../components/image";
import {MapPopup, MapMarker, MapControl} from "../../../../components/map";

import {google} from "google-maps-react"; // Install this package if needed
import {clusterLayer, clusterCountLayer, unclusteredPointLayer} from "./clusters/layers";

// ----------------------------------------------------------------------

MapMarkersPopups.propTypes = {
    data: PropTypes.array,
    selectedCoordinates: PropTypes.object,
    zoom: PropTypes.number,
    initialLongitude: PropTypes.number,
    initialLatitude: PropTypes.number,
};

// Cordenadas Iniciales (9, 53) -------->
function MapMarkersPopups({data, selectedCoordinates, zoom, initialLatitude = 53, initialLongitude = 9, ...other}) {
    const [popupInfo, setPopupInfo] = useState(null);
    const [clusterPoints, setClusterPoints] = useState(null);
    const [singlePoints, setSinglePoints] = useState(null);

    const initialClusterRadius = 1.0; // Initial cluster radius for lowest zoom level
    const minClusterSize = 5; // Minimum number of points to trigger clustering
    const maxZoom = 15; // Maximum zoom level for detailed view

    const mapRef = useRef(null);


    useEffect(() => {
        let {lat, long} = selectedCoordinates;
        if (lat === null || lat === undefined || Number.isNaN(lat) || long === null || long === undefined || Number.isNaN(long)) {
            console.log("no lat");
            return;
        }

        mapRef.current?.easeTo({
            center: [long, lat],
            animate: true,
            zoom: zoom,
            duration: 1000,
        });
    }, [zoom, selectedCoordinates]);

    let navigate = useNavigate();

    const onClick = (event) => {
        const feature = event.features?.[0];

        const clusterId = feature?.properties?.cluster_id;

        const mapboxSource = mapRef.current?.getSource("locations");

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) {
                return;
            }

            if (Number.isNaN(zoom)) {
                console.log(feature);

                setPopupInfo(null);
                setTimeout(() => {
                    let {ciudades, destacada, fotos, latlng, mapas} = feature?.properties;
                    let properties = {
                        ...feature?.properties,
                        ciudades: JSON.parse(ciudades),
                        destacada: JSON.parse(destacada),
                        fotos: JSON.parse(fotos),
                        latlng: JSON.parse(latlng),
                        mapas: JSON.parse(mapas),
                    };
                    setPopupInfo(properties);
                }, 10);

                return;
            }

            console.log(feature?.geometry?.coordinates);

            mapRef.current?.easeTo({
                center: feature?.geometry?.coordinates,
                zoom: zoom,
                duration: 500,
            });
        });
    };

    const initGeoSource = () => {
        let features = data.map((location) => {
            let [lat, long] = location.latlng;
            return turf.point([long, lat], {...location});
        });
        return turf.featureCollection(features);
    };

    console.log(popupInfo)

    return (
        <Map
            initialViewState={{
                zoom: 0.5,
                longitude: initialLongitude,
                latitude: initialLatitude,
            }}
            interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
            onClick={onClick}
            ref={mapRef}
            // zoom={zoom}
            {...other}
        >

            <Source id="locations" type="geojson" data={initGeoSource()} cluster clusterMaxZoom={14} clusterRadius={50}>
                <Layer {...clusterLayer} cursor/>
                <Layer {...clusterCountLayer} />
                <Layer
                    {...unclusteredPointLayer}
                    sx={{
                        cursor: "pointer",
                    }}
                />
            </Source>

            <MapControl/>

            {/* {data.map((city, index) => (
        // <MapMarker
          key={`marker-${index}`}
          latitude={city.latlng[0]}
          longitude={city.latlng[1]}
          onClick={(event) => {
            event.originalEvent.stopPropagation();
            setPopupInfo(null);
            setTimeout(() => {
              setPopupInfo(city);
            }, 10);
          }}
        />
      ))} */}

            {popupInfo && (
                <Link href={`/route/${popupInfo.id}`}  target="_blank" rel="noopener">
                    <MapPopup latitude={popupInfo.latlng[0]} longitude={popupInfo.latlng[1]}
                              onClose={() => setPopupInfo(null)}>
                        <Box
                            sx={{color: "common.white"}}
                        >
                            <Box
                                sx={{
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        height: "18px",
                                        paddingLeft: "4px",
                                        // borderRadius: "4px",

                                    }}
                                />
                                <Image
                                    sx={{ width: 22, mr: 1}}
                                    alt="United States"
                                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${popupInfo.bandera}.svg`}/>
                              <Link href={`/route/${popupInfo.id}`}  target="_blank" rel="noopener">

                              <Typography variant="subtitle2">{popupInfo.nombre}</Typography>
                              </Link>
                            </Box>

                            {/* <Typography component="div" variant="caption">
              Timezones: {popupInfo.timezones}
            </Typography>

            <Typography component="div" variant="caption">
              Lat: {popupInfo.latlng[0]}
            </Typography>

            <Typography component="div" variant="caption">
              Long: {popupInfo.latlng[1]}
            </Typography> */}

                            {/* Cambiar el tamaño de caja que se abre al dar click en los punteros rojos */}
                            <Image alt={popupInfo.name} src={`https://d23scsgq1o4214.cloudfront.net/${popupInfo.foto}`}
                                   ratio="4/3" sx={{mt: 1, borderRadius: 1, height: 80, width: 150}}/>
                        </Box>
                    </MapPopup>
                </Link>
            )}
        </Map>
    );
}

export default memo(MapMarkersPopups);
