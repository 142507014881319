import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
// @mui
import {alpha, useTheme} from "@mui/material/styles";
import {Box, Paper, Link, CardContent} from "@mui/material";
// utils
import {bgGradient} from "../../../../utils/cssStyles";
// components
import Image from "../../../../components/image";
import Iconify from "../../../../components/iconify";
import TextMaxLine from "../../../../components/text-max-line";
import Carousel, {CarouselArrows} from "../../../../components/carousel";
import useResponsive, {useWidth} from "../../../../hooks/useResponsive";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

CarouselCenterMode.propTypes = {
    data: PropTypes.array,
};

export default function CarouselCenterMode({data}) {
    const carouselRef = useRef(null);

    const theme = useTheme();

    const isMobile = useResponsive('down', 'sm');
    const isMediumStart  = useResponsive("up", 640)
    const isMediumEnd = useResponsive("down", 720)

    const isMedium = isMediumStart && isMediumEnd;

    // get rhe width of the screen



    const carouselSettings = {
        slidesToShow: isMobile ? 1 : 2,
        centerMode: !isMobile,
        centerPadding: isMedium ? "40px" : isMedium ? "20px" : "60px",
        rtl: Boolean(theme.direction === "rtl"),
        responsive: [

            // {
            //     breakpoint: 800,
            //     settings: {slidesToShow: 1, centerPadding: "30px"},
            // },
            //
            // {
            //     breakpoint: 500,
            //     settings: {slidesToShow: 1, centerPadding: "50px"},
            // },
            //
            // {
            //     breakpoint: 200,
            //     settings: {slidesToShow: 1, centerPadding: "50px"},
            // },
        ],
    };

    const handlePrev = () => {
        carouselRef.current?.slickPrev();
    };

    const handleNext = () => {
        carouselRef.current?.slickNext();
    };


    return (
        <Box
            sx={{
                overflow: "hidden",
                position: "relative",
            }}
        >
            <CarouselArrows
                filled
                // icon="noto:rightwards-hand"
                onNext={handleNext}
                onPrevious={handlePrev}
            >
                <Carousel ref={carouselRef} {...carouselSettings}>
                    {data.map((item) => (
                        <Box key={item.id} sx={{px: 1}}>
                            <CarouselItem item={item}/>
                        </Box>
                    ))}
                </Carousel>
            </CarouselArrows>
        </Box>
    );
}

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
    item: PropTypes.object,
};

function CarouselItem({item}) {
    const theme = useTheme();


    const {image, titulo, link, id} = item;
    console.log(item)

    const isMobile = useResponsive('down', 'sm');
    const isMedium = useResponsive('down', 'md');


    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Paper
            sx={{
                borderRadius: 2,
                overflow: "hidden",
                position: "relative",
            }}
        >
            <Box >

                <iframe title={id}
                        src={link}
                        width={isMobile ? screenWidth : 500} height="300"></iframe>
                <Link href={link} target="_blank" rel="noopener">
                    <h3>{titulo}</h3>
                </Link>
            </Box>
        </Paper>
    );
}
