import { Helmet } from "react-helmet-async";

// sections
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// @mui
import { Box, Container, Stack, Grid, AppBar, List, ListItem, Toolbar, Typography, CardHeader, CardContent, Card, Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import VistaActividad from "./VistaActividad";

// routes
// config
import { NAV, HEADER, MAP_API } from "../../config-global";
// components
import Iconify from "../../components/iconify";
import { NavSectionMini, NavSectionVertical, NavSectionHorizontal } from "../../components/nav-section";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";

// routes
import { PATH_PAGE } from "../../routes/paths";
import Image from '../../components/image';

// config
// _mock_
import { cities as CITIES } from "../../_mock/map/cities";
import { routes as COUNTRIES } from "../../_mock/map/countries";
// components
// sections
import MapMarkersPopups from "../../sections/_examples/extra/map/MapMarkersPopups";
import _mock from "../../_mock";
import { useSettingsContext } from "../../components/settings";
import { number } from "prop-types";

// ----------------------------------------------------------------------

const IMG = [_mock.image.cover(2), _mock.image.cover(3), _mock.image.cover(4), _mock.image.cover(5), _mock.image.cover(8)];


const getLong = (searchParams) => {
  let long = searchParams.get("long");
  if (long === null || long === undefined || long === "" || Number.isNaN(long)) {
    console.log("no long");
    return 9;
  }

  return long;
};

const getLat = (searchParams) => {
  let lat = searchParams.get("lat");
  if (lat === null || lat === undefined || Number.isNaN(lat)) {
    console.log("no lat");
    return 53;
  }
  return lat;
};

export default function TestPage() {
  const { themeStretch } = useSettingsContext();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const minClusterSize = 5; // Minimum number of points to trigger clustering
  const maxZoom = 15; // Maximum zoom level for detailed view

  // useEffect(() => {}, [searchParams]);

  function countryToFlagImageURL(isoCode) {
    return `https://www.countryflags.io/${isoCode}/flat/64.png`;
  }

  return (
    // <Container maxWidth={themeStretch ? false : 'lg'}>
    <Stack
      sx={{
        px: 0,
        py: 0,
      }}
    >
      <Helmet>
        <title>Wayfy</title>
      </Helmet>

      <Stack spacing={0} sx={{ my: 0 }}>
        {/* <Typography variant="h6"> Nav Horizontal </Typography> */}

        <AppBar
          position="static"
          component="nav"
          color="default"
          sx={{
            boxShadow: 0,
            // top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }}
        >
          <Toolbar
            sx={{
              mt: {
                xs: 8,
                md: 9,
              },
            }}
          >
            <NavSectionHorizontal data={NAV_ITEMS} />
          </Toolbar>
        </AppBar>

        {/* <Card> */}
        {/* <CardHeader title="Markers & Popups" /> */}
        {/* <CardContent> */}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack>
              <Autocomplete
                sx={{ px: 2, py: 1 }}
                freeSolo
                options={COUNTRIES}
                getOptionLabel={(option) => option.nombre}
                renderOption={(props, option) => (
                  <Box component="li" {...props} sx={{ px: "8px !important" }}>
                    <Image
                        sx={{ width: 24, mr: 1.5}}
                        alt="United States"
                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${option.bandera}.svg`}/>
                    {option.nombre}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Busqueda"
                    onChange={(e) => console.log(e.target.value)}
                    onSelect={(e) => {
                      let item = COUNTRIES.find((item) => item.nombre === e.target.value);
                      navigate(`/route/${item.id !== undefined ? item.id : 1}`);
                    }}
                  />
                )}
              />
            </Stack>
            <StyledMapContainer sx={{ p: 0, m: 0 }}>
              <MapMarkersPopups
                {...baseSettings}
                zoom={parseFloat(searchParams.get("zoom")) || 0.8}
                selectedCoordinates={{
                  long: getLong(searchParams),
                  lat: getLat(searchParams),
                }}
                data={COUNTRIES}
                mapStyle={THEMES.streets}
              />
            </StyledMapContainer>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
    // </Container>
  );
}
const NAV_ITEMS = [
  {
    subheader: "Marketing",
    items: [
      {
        title: "America del Norte",
        path: "?lat=44.9989269&long=-100.4595518&zoom=3.3",
        icon: "Icono Norteamercia.png",
      },

      {
        title: "Latinoamerica",
        path: "?lat=-25.2688131&long=-65.0504944&zoom=3.2",
        icon: "Icono Latinoamerica.png"
      },
      {
        title: "Asia",
        path: "?lat=34.0479&long=100.6197&zoom=4",
        icon: "Icono Asia.png",
        // children: [
        //   { title: 'Blog Posts', path: '#' },
        //   { title: 'Blog Post', path: '#' },
        // ],
      },
      {
        title: "Europa",
        path: "?long=9&lat=53&zoom=4.3",
        icon: "Icono europa.png",
      },
      {
        title: "Africa-Oriente",
        path: "?lat=3.1021&long=18.2812&zoom=3.25",
        icon: 'Icono Africa-Oriente medio.png'
        // icon: <Iconify icon="carbon:battery-full" />,
      },
      //   {
      //     title: 'Middle East',
      //     path: '?lat=29.2985&long=42.5510&zoom=3.5',
      //     icon: <Iconify icon="carbon:basketball" />,
      //     // children: [
      //     //   { title: 'Tours', path: '#' },
      //     //   { title: 'Tour', path: '#' },
      //     // ],
      //   },
      {
        title: "Australia",
        path: "?lat=-24.56884974&long=132.858346&zoom=4.3",
        icon: "Icono Oceania.png",
        // children: [
        //   { title: 'Checkout', path: '#' },
        //   { title: 'Checkout Complete', path: '#' },
        // ],
      },
    ],
  },
  //   {
  //     subheader: 'Travel',
  //     items: [
  //       {
  //         title: 'Level 1',
  //         path: '#',
  //         icon: <Iconify icon="carbon:play" />,
  //         // children: [
  //         //   { title: 'Level 2.1', path: '#' },
  //         //   { title: 'Level 2.2', path: '#' },
  //         //   {
  //         //     title: 'Level 2.3',
  //         //     path: '#',
  //         //     children: [
  //         //       { title: 'Level 3.1', path: '#' },
  //         //       { title: 'Level 3.2', path: '#' },
  //         //     ],
  //         //   },
  //         // ],
  //       },
  //     ],
  //   },
];

const StyledMapContainer = styled("div")(({ theme }) => ({
  zIndex: 0,
  height: "65.5vh",
  maxHeight: "90vh",
  minHeight: "77vh",
  overflow: "hidden",
  position: "relative",
  borderRadius: 0,
  "& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right": {
    display: "none",
  },
}));

const THEMES = {
  streets: "mapbox://styles/mapbox/streets-v11",
  outdoors: "mapbox://styles/mapbox/outdoors-v11",
  light: "mapbox://styles/mapbox/light-v10",
  dark: "mapbox://styles/mapbox/dark-v10",
  satellite: "mapbox://styles/mapbox/satellite-v9",
  satelliteStreets: "mapbox://styles/mapbox/satellite-streets-v11",
};

const baseSettings = {
  mapboxAccessToken: MAP_API,
  minZoom: 1,
};
