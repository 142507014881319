import PropTypes from "prop-types";
import { paramCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Card, Link, Stack, Typography } from "@mui/material";
// routes
// utils
// redux
import { useDispatch } from "../../redux/store";
import { addToCart } from "../../redux/slices/product";
// components
import Image from "../../components/image";
import Markdown from "src/components/markdown";

// ----------------------------------------------------------------------

VistaActividad.propTypes = {
  tutorial: PropTypes.object,
};

export default function VistaActividad({ tutorial }) {
  const { name, description, link, logo, foto, url } = tutorial;

  const dispatch = useDispatch();

  return (
    <Card
      sx={{
        "&:hover .add-cart-btn": {
          opacity: 1,
        },
      }}
    >
      <Stack sx={{ position: "relative", p: 1 }} direction="row">
        <Image alt={name} src={foto} sx={{ borderRadius: 1.5, height: 80, width: 100 }} />
        <Stack spacing={0} sx={{ py: 0.5, px: 3 }}>
          <Stack spacing={1}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Image alt={name} src={foto} sx={{ borderRadius: 1.5, height: 30, width: 30 }} />
              <Typography color="inherit" variant="subtitle1">
                <Link href={link} target="_blank" rel="noopener">
                  {name}
                </Link>
              </Typography>
            </Stack>

            <Markdown children={description} />
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
//  ratio: PropTypes.oneOf(['4/3', '3/4', '6/4', '4/6', '16/9', '9/16', '21/9', '9/21', '1/1']),
