import { Helmet } from "react-helmet-async";

// sections
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// @mui
import { Box, Container, Stack, AppBar, Toolbar, Typography, CardHeader, CardContent, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import VistaActividad from "./VistaActividad";

// routes
// config
import { NAV, HEADER, MAP_API } from "../../config-global";
// components
import Iconify from "../../components/iconify";
import { NavSectionMini, NavSectionVertical, NavSectionHorizontal } from "../../components/nav-section";
import { useSettingsContext } from "../../components/settings";

import CustomBreadcrumbs from "../../components/custom-breadcrumbs";

// routes
import { PATH_PAGE } from "../../routes/paths";
// config
// _mock_
import { cities as CITIES } from "../../_mock/map/cities";
import { routes as COUNTRIES } from "../../_mock/map/countries";
// components
// sections
import MapMarkersPopups from "../../sections/_examples/extra/map/MapMarkersPopups";
import _mock from "../../_mock";

// ----------------------------------------------------------------------

const IMG = [_mock.image.cover(2), _mock.image.cover(3), _mock.image.cover(4), _mock.image.cover(5), _mock.image.cover(8)];

export default function HomePageTest() {
  const { themeStretch } = useSettingsContext();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    console.log(searchParams.get("lat"));
  }, [searchParams]);

  return (
    <Container maxWidth={themeStretch ? false : "md"}>
      <Stack sx={{ px: 0, py: 5 }}>
        <Helmet>
          <title> Register | Minimal UI</title>
        </Helmet>

        <Stack spacing={0} sx={{ mb: 0 }}>
          {/* <Typography variant="h6"> Nav Horizontal </Typography> */}

          {/* <Card> */}
          {/* <CardHeader title="Markers & Popups" /> */}
          {/* <CardContent> */}
          <Box
            gap={3}
            display="grid"
            // sx={{ p: 25 }}
            gridTemplateColumns={{
              xs: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
          >
            <VistaActividad
              tutorial={{
                name: "Tutorial 1",
                description: "Tutorial description",
                url: _mock.image.cover(1),
              }}
            />
            <VistaActividad
              tutorial={{
                name: "Tutorial 2",
                description: "Tutorial description",
                url: _mock.image.cover(2),
              }}
            />
            <VistaActividad
              tutorial={{
                name: "Tutorial 3",
                description: "Tutorial description",
                url: _mock.image.cover(3),
              }}
            />
            <VistaActividad
              tutorial={{
                name: "Tutorial 3",
                description: "Tutorial description",
                url: _mock.image.cover(3),
              }}
            />
            <VistaActividad
              tutorial={{
                name: "Tutorial 3",
                description: "Tutorial description",
                url: _mock.image.cover(3),
              }}
            />
            <VistaActividad
              tutorial={{
                name: "Tutorial 3",
                description: "Tutorial description",
                url: _mock.image.cover(3),
              }}
            />
            <VistaActividad
              tutorial={{
                name: "Tutorial 3",
                description: "Tutorial description",
                url: _mock.image.cover(3),
              }}
            />
            <VistaActividad
              tutorial={{
                name: "Tutorial 3",
                description: "Tutorial description",
                url: _mock.image.cover(3),
              }}
            />
            <VistaActividad
              tutorial={{
                name: "Tutorial 3",
                description: "Tutorial description",
                url: _mock.image.cover(3),
              }}
            />
          </Box>

          {/* </CardContent> */}
          {/* </Card> */}
        </Stack>
      </Stack>
    </Container>
  );
}
