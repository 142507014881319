import _mock from "../_mock";

// ----------------------------------------------------------------------

export const logoImage = "wayfy/Logo 2.jpg";

export const routes = [
  {
    id: 1, // no quitar
    latlng: [31.968361515315813, 35.91463744780874],
    nombre: "Jordania",
    bandera: "JO",
    foto: "Oriente medio/Jordania/gabor-koszegi-T6X1NkFgkkk-unsplash.jpg",
    fotos: [
      "Oriente medio/Jordania/salim-benaadjou-qnPlISQNxl0-unsplash.jpg",
      "Oriente medio/Jordania/desert-4954346.jpg",
      "Oriente medio/Jordania/rinaldo-vadi-GLfU-ccVpfg-unsplash.jpg",
      "Oriente medio/Jordania/hisham-zayadneh-2B5lDWbitXE-unsplash.jpg",
      "Oriente medio/Jordania/gabor-koszegi-T6X1NkFgkkk-unsplash.jpg",
      "Oriente medio/Jordania/desert-4940300.jpg",
      "Oriente medio/Jordania/desert-1633049.jpg",
      "Oriente medio/Jordania/seascape-5542388.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Jordania Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1U-_ZvZ4J8kVhAlMTNgFOjNAZQMk6gVQ&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YnRPYvYm7vCP7mUh43saWnCWee47k0s&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=111Hctb_zBTb2p40VDlFtfnSWj9YVabc&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1VrVSqS5TKJVvfLl3aAjjsXQAogHzf5E&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `<h1 id="sample-markdown">Sample Markdown</h1>
<p>This is some basic, sample markdown.</p>
<h2 id="second-heading">Second Heading</h2>
<ul>
<li>Unordered lists, and:<ol>
<li>One</li>
<li>Two</li>
<li>Three</li>
</ol>
</li>
<li>More</li>
</ul>
<blockquote>
<p>Blockquote</p>
</blockquote>
<p>And <strong>bold</strong>, <em>italics</em>, and even <em>italics and later <strong>bold</strong></em>. Even <del>strikethrough</del>. <a href="https://markdowntohtml.com">A link</a> to somewhere.</p>
<p>And code highlighting:</p>
<pre><code class="lang-js"><span class="hljs-keyword">var</span> foo = <span class="hljs-string">'bar'</span>;

<span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">baz</span><span class="hljs-params">(s)</span> </span>{
   <span class="hljs-keyword">return</span> foo + <span class="hljs-string">':'</span> + s;
}
</code></pre>
<p>Or inline code like <code>var foo = &#39;bar&#39;;</code>.</p>
<p>Or an image of bears</p>
<p><img src="http://placebear.com/200/200" alt="bears"></p>
<p>The end ...</p>`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: ``,
      },
      {
        id: 3,
        titulo: "Top 10 Consejos",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "El tiempo",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Internet",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Otros",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Petra",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Wadi Rum",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Wadi Mujib",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Mar muerto",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Ammán",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Áqaba",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 8,
        ciudad: "Gerasa",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Ammán",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Petra",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Wadi Rum",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Mar muerto",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Áqaba",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Gerasa",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otras",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 2, // no quitar
    latlng: [65.00180979609735, -18.718971445678395],
    nombre: "Islandia",
    bandera: "IS",
    foto: "Europa/Europa/Islandia/iceland-2111811.jpg",
    fotos: [
      "Europa/Europa/Islandia/iceland-2111811.jpg",
      "Europa/Europa/Islandia/pexels-jos-van-ouwerkerk-12501123.jpg",
      "Europa/Europa/Islandia/volcano-7396466.jpg",
      "Europa/Europa/Islandia/frank-denney-vCqclUnCg_8-unsplash.jpg",
      "Europa/Europa/Islandia/j-yeo-ozhQshZxiBE-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Islandia Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1BaViFdODicbU8mJL9V05Zml-dT8SAA8&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1AqwaQunainc-bNNlU7VEn-mBxy1ChB4&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1SvBq-dJ_ANzQeiwdjMvTeoShEPV7Chk&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=12mhAtaGxrfdYOxyVTqMHWT5_jr0YvK4&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1BaViFdODicbU8mJL9V05Zml-dT8SAA8&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Reikiavik",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Sur",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Este",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Tierras altas",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Norte",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Oeste + Fiordos",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Reikiavik",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Sur",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Este",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Tierras altas",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Norte",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Oeste + Fiordos",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 Imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otras",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 3, // no quitar
    latlng: [3.289124449970572, 101.65326277116495],
    nombre: "Malasia",
    bandera: "MY",
    foto: "Sudeste asiático/Malasia/wengang-zhai-HCIr35bwff0-unsplash.jpg",
    fotos: [
      "Sudeste asiático/Malasia/wengang-zhai-HCIr35bwff0-unsplash.jpg",
      "Sudeste asiático/Malasia/pexels-bearmax-3960199.jpg",
      "Sudeste asiático/Malasia/temple-4580960.jpg",
      "Sudeste asiático/Malasia/pexels-pok-rie-295813.jpg",
      "Sudeste asiático/Malasia/pexels-pok-rie-5255296.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Malasia Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1abWwl5_mlWoXXuDF5z472PSrldeBeVI&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1abWwl5_mlWoXXuDF5z472PSrldeBeVI&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1abWwl5_mlWoXXuDF5z472PSrldeBeVI&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1abWwl5_mlWoXXuDF5z472PSrldeBeVI&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1abWwl5_mlWoXXuDF5z472PSrldeBeVI&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Kuala Lumpur",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Singapur",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Melaka",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Cameron Highlands",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Georgetown",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Perhentian",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Borneo Malayo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Kuala Lumpur",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Singapur",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Melaka",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Cameron Highlands",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Georgetown",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Perhentian",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Borneo Malayo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otras",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 4, // no quitar
    latlng: [21.02738276813682, 105.83009666924147],
    nombre: "Vietnam",
    bandera: "VN",
    foto: "Sudeste asiático/Vietnam/IMG_2968.JPG",
    fotos: [
      "Sudeste asiático/Vietnam/IMG_2968.JPG",
      "Sudeste asiático/Vietnam/IMG_2976.JPG",
      "Sudeste asiático/Vietnam/rice-terraces-164410_1920.jpg",
      "Sudeste asiático/Vietnam/E6B5C10A-6F1E-4E23-A035-7E6A3967126B.jpg",
      "Sudeste asiático/Vietnam/47C7F393-C510-4F2B-B014-60FED64B0049.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Vietnam Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1X6RicLtQrh63h6J8HAFevsLFVc7q_j0&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1X6RicLtQrh63h6J8HAFevsLFVc7q_j0&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1X6RicLtQrh63h6J8HAFevsLFVc7q_j0&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1X6RicLtQrh63h6J8HAFevsLFVc7q_j0&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1X6RicLtQrh63h6J8HAFevsLFVc7q_j0&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Hanói",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Halong Bay",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Sa Pa",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Ninh Binh",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Hoi An",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Ho Chi Minh",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Hanói",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Halong Bay",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Sa Pa",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Ninh Binh",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Hoi An",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Ho Chi Minh",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otras",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 5, // no quitar
    latlng: [-8.392582498118532, 115.18677610149003],
    nombre: "Indonesia",
    bandera: "ID",
    foto: "Sudeste asiático/Indonesia/zunnoon-ahmed-06Fc_R9hA8w-unsplash.jpg",
    fotos: [
      "Sudeste asiático/Indonesia/zunnoon-ahmed-06Fc_R9hA8w-unsplash.jpg",
      "Sudeste asiático/Indonesia/tom-bixler-cSkl8pQ33mI-unsplash.jpg",
      "Sudeste asiático/Indonesia/sebastian-pena-lambarri-Ke8EuI2xOUc-unsplash.jpg",
      "Sudeste asiático/Indonesia/fajruddin-mudzakkir-TG50QzQzZm0-unsplash.jpg",
      "Sudeste asiático/Indonesia/fajruddin-mudzakkir-5tAGrREDQbs-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Indonesia completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1_PFUEti-O1CrIvMfhULLM8FEuhHiMrI&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1_PFUEti-O1CrIvMfhULLM8FEuhHiMrI&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1_PFUEti-O1CrIvMfhULLM8FEuhHiMrI&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1_PFUEti-O1CrIvMfhULLM8FEuhHiMrI&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1_PFUEti-O1CrIvMfhULLM8FEuhHiMrI&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "yoyakarta",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Java Oriental",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Borneo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bali",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Nusa Penida",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Gili Island",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 8,
        ciudad: "Lombok",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 9,
        ciudad: "Komodo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "yoyakarta",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Java Oriental",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Borneo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Bali",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Nusa Penida",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Gili Island",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Lombok",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 8,
        ciudad: "Komodo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 Imprescindibles ",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 6, // no quitar
    latlng: [13.781800188309028, 100.50672923416785],
    nombre: "Tailandia-Peninsula",
    bandera: "TH",
    foto: "Sudeste asiático/Tailandia/animals-1782436.jpg",
    fotos: [
      "Sudeste asiático/Tailandia/animals-1782436.jpg",
      "Sudeste asiático/Tailandia/oliver-spicer-tHJ1_WKRmyg-unsplash.jpg",
      "Sudeste asiático/Tailandia/ron-iligan-ezCV4Nderc8-unsplash.jpg",
      "Sudeste asiático/Tailandia/geoff-greenwood-rH1aA4TqGms-unsplash.jpg",
      "Sudeste asiático/Tailandia/elephant-1822492.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Tailandia (Peninsula) Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=19b3a1FPhd_z4Kg4usul6n1BUfcnb-lI&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=19b3a1FPhd_z4Kg4usul6n1BUfcnb-lI&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=19b3a1FPhd_z4Kg4usul6n1BUfcnb-lI&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=19b3a1FPhd_z4Kg4usul6n1BUfcnb-lI&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=19b3a1FPhd_z4Kg4usul6n1BUfcnb-lI&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Bangkok",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Ayutthaya",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Chiang Mai",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Chiang Rai",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Bangkok",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Ayutthaya",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Chiang Mai",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Chiang Rai",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescidibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 7, // no quitar
    latlng: [13.413857302674524, 103.86709298612419],
    nombre: "Camboya",
    bandera: "KH",
    foto: "Sudeste asiático/Camboya/sebastian-latorre-lrG4nki2kYM-unsplash.jpg",
    fotos: [
      "Sudeste asiático/Camboya/sebastian-latorre-lrG4nki2kYM-unsplash.jpg",
      "Sudeste asiático/Camboya/james-wheeler-9zXMb-E8pI0-unsplash.jpg",
      "Sudeste asiático/Camboya/paul-szewczyk-XRVJIMsXc1A-unsplash.jpg",
      "Sudeste asiático/Camboya/anton-sulsky-N7JcYscJTUA-unsplash.jpg",
      "Sudeste asiático/Camboya/pexels-иван-лемехов-8761372.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Camboya Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YhVAZipQ0Nr5BIXZal9sEiDz0olJkkI&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YhVAZipQ0Nr5BIXZal9sEiDz0olJkkI&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YhVAZipQ0Nr5BIXZal9sEiDz0olJkkI&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YhVAZipQ0Nr5BIXZal9sEiDz0olJkkI&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YhVAZipQ0Nr5BIXZal9sEiDz0olJkkI&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "siemp Reap",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Phom Penh",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Koh Rong",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Sambor Prei Kuk",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Kratié/Kompung Cham",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "siemp Reap",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Phom Penh",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Koh Rong",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Sambor Prei Kuk",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Kratié/Kompung Cham",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 8, // no quitar
    latlng: [29.977502108663106, 31.133086630015857],
    nombre: "Egipto",
    bandera: "EG",
    foto: "Oriente medio/Egipto/pexels-hisham-elshazli-10131669.jpg",
    fotos: [
      "Oriente medio/Egipto/pexels-hisham-elshazli-10131669.jpg",
      "Oriente medio/Egipto/hieroglyphs-429863.jpg",
      "Oriente medio/Egipto/roxanne-desgagnes-qwbA2RcohIU-unsplash.jpg",
      "Oriente medio/Egipto/youhana-nassif-3SPcB0FC-jI-unsplash.jpg",
      "Oriente medio/Egipto/lea-kobal-VVzLCuY1xCk-unsplash.jpg",
      "Oriente medio/Egipto/andres-dallimonti-fGwIVG97688-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Egipto Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1FlimVrOS2l6Uq46MVArfjduIOuihsp0&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1x5GDS1oa_h6j3LWaNrCwoFJ3EtBLilg&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ALD9iaqSgNWIdqEl8s1ANeAeE4iDr1E&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=178ygNhaW-mgogCRAziwWYtJbBqq6Fe0&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "El Cairo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Asuán",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Lúxor",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Mar Rojo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "El Cairo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Asuán",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Lúxor",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Mar Rojo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 9, // no quitar
    latlng: [31.777768327989165, 35.23271322084374],
    nombre: "Israel",
    bandera: "IL",
    foto: "Oriente medio/Israel/barbed-wire-1876148.jpg",
    fotos: [
      "Oriente medio/Israel/barbed-wire-1876148.jpg",
      "Oriente medio/Israel/stacey-franco-ex9KQrN1mj0-unsplash.jpg",
      "Oriente medio/Israel/via-dolorosa-706563.jpg",
      "Oriente medio/Israel/sander-crombach-d_so1tRFKJk-unsplash.jpg",
      "Oriente medio/Israel/shai-pal-lpQwaLWhw9Q-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpgg",
        titulo: "Israel Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1uCPcBR_ZdAPI4xSD-o2DSEIOhVaxYvA&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1CXdkLSe56LIlVicbrTTtG47ZZ4h8mus&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=17Xu70iwtus5JMaZlO9qj6xGiOtJ3ZkA&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1kRwVO5iRlFDZS4qy2wFEnfoA1CAi0FM&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Tel Aviv",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Jerusalén",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Norte de Israel",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Mar Muerto",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Eliat",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Tel Aviv",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Jerusalén",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Norte de Israel",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Mar muerto",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Eliat",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 10, // no quitar
    latlng: [7.741701336300311, 98.77313714422722],
    nombre: "Tailandia Islas",
    bandera: "TH",
    foto: "Sudeste asiático/Tailandia/pexels-oleksandr-pidvalnyi-1007657.jpg",
    fotos: [
      "Sudeste asiático/Tailandia/pexels-oleksandr-pidvalnyi-1007657.jpg",
      "Sudeste asiático/Tailandia/the-dk-photography-z6-wQHB6p2Y-unsplash.jpg",
      "Sudeste asiático/Tailandia/pexels-darren-lawrence-3417733.jpg",
      "Sudeste asiático/Tailandia/m-o-e-JoxepWVTrNE-unsplash.jpg",
      "Sudeste asiático/Tailandia/sara-dubler-Koei_7yYtIo-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Tailandia Islas",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ewL3PzbL_jOOCly1QDsLm-FsPMCnyT0&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ewL3PzbL_jOOCly1QDsLm-FsPMCnyT0&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ewL3PzbL_jOOCly1QDsLm-FsPMCnyT0&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ewL3PzbL_jOOCly1QDsLm-FsPMCnyT0&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ewL3PzbL_jOOCly1QDsLm-FsPMCnyT0&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Golfo de Tailandia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Phuket",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Krabi",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Golfo de Tailandia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Phuket",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Krabi",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 11, // no quitar
    latlng: [38.63631746992678, 34.81095035465973],
    nombre: "Turquía",
    bandera: "TR",
    foto: "Oriente medio/Turquia/pexels-soner-arkan-13337127.jpg",
    fotos: [
      "Oriente medio/Turquia/pexels-soner-arkan-13337127.jpg",
      "Oriente medio/Turquia/pexels-taryn-elliott-3889704.jpg",
      "Oriente medio/Turquia/eminonu-4772596.jpg",
      "Oriente medio/Turquia/pamukalle-7503036.jpg",
      "Oriente medio/Turquia/pexels-meruyert-gonullu-6243268.jpg",
      "Oriente medio/Turquia/antalya-2708343.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Turquia Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1HZmvYcfsVNmu28ViCE-n5PezJWXwOTk&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1wneJNzhBDKOaEYtKnIexRmPh8n5jzC8&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1AGe44SqbiKoqJ_VCO5OYobnC7sqfp8E&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1VD49P8IoNZqw3qaZAtPceJd3XwZ9Ng4&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Estambul",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Capadocia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Ankara",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Pamukkale",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Costa turquesa",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Esmirna",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Estambul",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Capadocia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Ankara",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Pamukkale",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Costa turquesa",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Esmirna",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 12, // no quitar
    latlng: [31.63197152314743, -7.990002457276372],
    nombre: "Marruecos",
    bandera: "MA",
    foto: "Oriente medio/Marruecos/carlos-leret-kiYzznir-uo-unsplash.jpg",
    fotos: [
      "Oriente medio/Marruecos/carlos-leret-kiYzznir-uo-unsplash.jpg",
      "Oriente medio/Marruecos/kyriacos-georgiou-CMmgfHQiYsc-unsplash.jpg",
      "Oriente medio/Marruecos/raul-cacho-oses-Y0I5DEbx8ck-unsplash.jpg",
      "Oriente medio/Marruecos/pexels-moussa-idrissi-3581916.jpg",
      "Oriente medio/Marruecos/junhan-foong-ERLAcTp-8MQ-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Marruecos Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1JS3kGdaY61MSG1Jv4eVysxbE75fLolY&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1V4IgeMFCgSbM0S174WovD9DcQEOqTLM&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ZdP9wJtYw9RGcZgzqhe1VAkLP6O6_SI&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1HXFxO5KP227YBSsbMUkjBXWld-ozwxM&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1JS3kGdaY61MSG1Jv4eVysxbE75fLolY&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Marrakech",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Esaguira/Agadir",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Rebat/Casablanca",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Fez",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Chefchaouen",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Tanger",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Marrakech",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Esaguira/Agadir",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Rebat/Casablanca",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Fez",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Chefchaouen",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Tanger",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "To 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 13, // no quitar
    latlng: [7.9572027028845635, 80.76025699773797],
    nombre: "Sri Lanka",
    bandera: "LK",
    foto: "Sudeste asiático/Sri Lanka/pexels-harsha-samaranayake-13391116.jpg",
    fotos: [
      "Sudeste asiático/Sri Lanka/pexels-harsha-samaranayake-13391116.jpg",
      "Sudeste asiático/Sri Lanka/yves-alarie-3R50kTNBKiE-unsplash.jpg",
      "Sudeste asiático/Sri Lanka/pexels-lyn-hoare-5042817.jpg",
      "Sudeste asiático/Sri Lanka/agnieszka-kowalczyk-44rwabC9jjI-unsplash.jpg",
      "Sudeste asiático/Sri Lanka/rajiv-perera-b1jeQiJwYQI-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Sri Lanka Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1snFRQW2ELnPdnmnKMnJ6ZWxFJ_J3VDY&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1snFRQW2ELnPdnmnKMnJ6ZWxFJ_J3VDY&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1snFRQW2ELnPdnmnKMnJ6ZWxFJ_J3VDY&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1snFRQW2ELnPdnmnKMnJ6ZWxFJ_J3VDY&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1snFRQW2ELnPdnmnKMnJ6ZWxFJ_J3VDY&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Colombo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Anuradhapura",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Nilaveli/Trincomale",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Sigiriya/Dambulla",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Kandy",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Nuwara Eliya/ Ella",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 8,
        ciudad: "Costa Sur",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Colombo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Anuradhapura",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Nilaveli/Trincomale",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Sigiriya/Dambulla",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Kandy",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Nuwara Eliya/ Ella",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Costa Sur",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 14, // no quitar
    latlng: [-13.226236792494314, -72.49732150214743],
    nombre: "Perú",
    bandera: "PE",
    foto: "Latam/Perú/junior-moran-zdk65luJOTI-unsplash.jpg",
    fotos: [
      "Latam/Perú/junior-moran-zdk65luJOTI-unsplash.jpg",
      "Latam/Perú/paul-vhZe9fd9MRs-unsplash.jpg",
      "Latam/Perú/mckayla-crump-hjanvZlqoB8-unsplash.jpg",
      "Latam/Perú/willian-justen-de-vasconcellos-hgxr9EbYZP0-unsplash.jpg",
      "Latam/Perú/alpaca-2214504.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Perú Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1KrbcX9JEJ2BDgSPefG70KUZZbC1ZWrc&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1KrbcX9JEJ2BDgSPefG70KUZZbC1ZWrc&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1KrbcX9JEJ2BDgSPefG70KUZZbC1ZWrc&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1KrbcX9JEJ2BDgSPefG70KUZZbC1ZWrc&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1KrbcX9JEJ2BDgSPefG70KUZZbC1ZWrc&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Lima",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Cusco",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Arequipa",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Paracas",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 2,
        ciudad: "Lima",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Cusco",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Arequipa",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Paracas",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 15, // no quitar
    latlng: [-20.12871867707619, -67.56194241296402],
    nombre: "Bolivia",
    bandera: "BO",
    foto: "Latam/Bolivia/bolivia-2965507.jpg",
    fotos: [
      "Latam/Bolivia/bolivia-2965507.jpg",
      "Latam/Bolivia/sifan-liu-Ry6Imrao4hE-unsplash.jpg",
      "Latam/Bolivia/railroad-6055089.jpg",
      "Latam/Bolivia/snowscat-ahAHZzVEEjo-unsplash.jpg",
      "Latam/Bolivia/diego-aguilar-JlLFykfuXcQ-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Bolivia Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1AzAwIbXy7POAcKw6N-8dIFQdoO7C7fs&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1AzAwIbXy7POAcKw6N-8dIFQdoO7C7fs&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1AzAwIbXy7POAcKw6N-8dIFQdoO7C7fs&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1AzAwIbXy7POAcKw6N-8dIFQdoO7C7fs&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1AzAwIbXy7POAcKw6N-8dIFQdoO7C7fs&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "La Paz",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Salar de Uyuni",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Sucre/Potosí",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "La Paz",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Salar de Uyuni",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Sucre/Potosí",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 16, // no quitar
    latlng: [8.98170306677724, -79.5245580618512],
    nombre: "Panamá",
    bandera: "PA",
    foto: "Latam/Panamá/sol-cerrud-y0izpAV1hCk-unsplash.jpg",
    fotos: [
      "Latam/Panamá/sol-cerrud-y0izpAV1hCk-unsplash.jpg",
      "Latam/Panamá/luis-aleman-JBjd0IBIAl8-unsplash.jpg",
      "Latam/Panamá/chema-photo-T9EwqJiADak-unsplash.jpg",
      "Latam/Panamá/pexels-karina-castillo-7204647.jpg",
      "Latam/Panamá/darren-miller-F0MmnUS2fwc-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Panamá Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1-VqnULUI-JGECN9Ostw9wUlGVdMuc5w&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1-VqnULUI-JGECN9Ostw9wUlGVdMuc5w&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1-VqnULUI-JGECN9Ostw9wUlGVdMuc5w&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1-VqnULUI-JGECN9Ostw9wUlGVdMuc5w&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1-VqnULUI-JGECN9Ostw9wUlGVdMuc5w&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Ciudad de Panamá",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "San Blas",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Bocas del Toro",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Valle de Antón",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Peninsula de Azuero",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Coiba/Boca Chica",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 8,
        ciudad: "Boquete",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Ciudad de Panamá",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "San Blas",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Bocas del Toro",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valle de Antón",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Peninsula de Azuero",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Coiba/Boca Chica",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Boquete",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 17, // no quitar
    latlng: [4.8494644050234825, -74.04945183796524],
    nombre: "Colombia",
    bandera: "CO",
    foto: "Latam/Colombia/pexels-chris-rodriguez-2884864.jpg",
    fotos: [
      "Latam/Colombia/pexels-chris-rodriguez-2884864.jpg",
      "Latam/Colombia/pexels-darren-lawrence-3417784.jpg",
      "Latam/Colombia/coffee-2538206.jpg",
      "Latam/Colombia/datingscout-PRJWxwDmtsE-unsplash.jpg",
      "Latam/Colombia/leandro-loureiro-J0suKy48jfk-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Colombia Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1pGMTFaD-J0iBmJ7tsEEqc8K1MJ42B0g&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1pGMTFaD-J0iBmJ7tsEEqc8K1MJ42B0g&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1pGMTFaD-J0iBmJ7tsEEqc8K1MJ42B0g&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1pGMTFaD-J0iBmJ7tsEEqc8K1MJ42B0g&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1pGMTFaD-J0iBmJ7tsEEqc8K1MJ42B0g&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Bogotá",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Eje cafetero",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Medellín",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Cartagena de indias",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Santa Marta/Parque Tayrona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Bogotá",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Eje cafetero",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Medellín",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Cartagena de indias",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Santa Marta/Parque Tayrona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 18, // no quitar
    latlng: [23.116950053111104, -82.3591037571142],
    nombre: "Cuba",
    bandera: "CU",
    foto: "Latam/Cuba/alexander-kunze-uLh71gTmZ4g-unsplash.jpg",
    fotos: [
      "Latam/Cuba/alexander-kunze-uLh71gTmZ4g-unsplash.jpg",
      "Latam/Cuba/ishan-seefromthesky-rj8fMHNPXbg-unsplash.jpg",
      "Latam/Cuba/cuba-660265.jpg",
      "Latam/Cuba/pexels-mehmet-turgut-kirkgoz-11769033.jpg",
      "Latam/Cuba/jeremy-stewardson-ZGhfklSefAs-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Cuba Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1No1vMDBd_FIMBCwpNscIc0-b7dE2pHo&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1No1vMDBd_FIMBCwpNscIc0-b7dE2pHo&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1No1vMDBd_FIMBCwpNscIc0-b7dE2pHo&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1No1vMDBd_FIMBCwpNscIc0-b7dE2pHo&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1No1vMDBd_FIMBCwpNscIc0-b7dE2pHo&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "General",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "La Habana",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Viñales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Trinidad",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Varadero/Cayos",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Santiago de Cuba",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "La Habana",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Viñales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Trinidad",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Varadero/Cayos",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Santiago de Cuba",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 19, // no quitar
    latlng: [10.000069501732789, -84.1085156803585],
    nombre: "Costa Rica",
    bandera: "CR",
    foto: "Latam/Costa rica/stephanie-leblanc-JLMEZxBcXCU-unsplash.jpg",
    fotos: [
      "Latam/Costa rica/stephanie-leblanc-JLMEZxBcXCU-unsplash.jpg",
      "Latam/Costa rica/christina-victoria-craft-IJ0SB3fu_WI-unsplash.jpg",
      "Latam/Costa rica/juanma-clemente-alloza-tHbSmexz5Mk-unsplash.jpg",
      "Latam/Costa rica/volcano-2355772.jpg",
      "Latam/Costa rica/adrian-valverde--TvZ6VB915M-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Costa Rica Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Ooa0f-94ep-TUneW_XKDGAgSHoyyoJs&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Ooa0f-94ep-TUneW_XKDGAgSHoyyoJs&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Ooa0f-94ep-TUneW_XKDGAgSHoyyoJs&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Ooa0f-94ep-TUneW_XKDGAgSHoyyoJs&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Ooa0f-94ep-TUneW_XKDGAgSHoyyoJs&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "San José",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Tortuguero",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Puerto viejo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Volcan Arenal",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Corcovado/Manuel Antonio",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Guanacaste",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "San José",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Tortuguero",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Puerto viejo",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Volcan Arenal",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Corcovado/Manuel Antonio",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Guanacaste",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 20, // no quitar
    latlng: [12.4578825165956, 123.13525830805116],
    nombre: "Filipinas",
    bandera: "PH",
    foto: "Sudeste asiático/Filipinas/cris-tagupa-1FWlno_goyQ-unsplash.jpg",
    fotos: [
      "Sudeste asiático/Filipinas/cris-tagupa-1FWlno_goyQ-unsplash.jpg",
      "Sudeste asiático/Filipinas/jamie-matocinos-1xsiGCqnAyo-unsplash.jpg",
      "Sudeste asiático/Filipinas/agriculture-1807570.jpg",
      "Sudeste asiático/Filipinas/john-hernandez-IL-fBBnF-GU-unsplash.jpg",
      "Sudeste asiático/Filipinas/sand-3159359.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Filipinas Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=117ROVQZpAM9ARzYsojB3lPq5eKKhYwU&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=117ROVQZpAM9ARzYsojB3lPq5eKKhYwU&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=117ROVQZpAM9ARzYsojB3lPq5eKKhYwU&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=117ROVQZpAM9ARzYsojB3lPq5eKKhYwU&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=117ROVQZpAM9ARzYsojB3lPq5eKKhYwU&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Manila",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Boracay",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Palawan",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bohol",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Cabú",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 7,
        ciudad: "Siargao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Manila",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Boracay",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Palawan",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Bohol",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Cabú",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 6,
        ciudad: "Siargao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Top 10 imprescindibles",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Generales",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Otros",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 21, // no quitar
    latlng: [41.90334266526876, 12.493418269473139],
    nombre: "Roma",
    bandera: "IT",
    foto: "Ciudades europeas/Roma/colosseum-792202.jpg",
    fotos: [
      "Ciudades europeas/Roma/colosseum-792202.jpg",
      "Ciudades europeas/Roma/pexels-julius-silver-753639.jpg",
      "Ciudades europeas/Roma/aleksandr-popov-GmySGLNon8c-unsplash.jpg",
      "Ciudades europeas/Roma/pexels-fabio-minetto-13216157.jpg",
      "Ciudades europeas/Roma/spencer-davis-haLyuhP6oLE-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Roma Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1TzKDL0esLSyeCCAxUKDEYgfVhHPMA0U&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1swk2k28NFkFdMvE5gVb8L4v1nOWzWqY&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ceFAZ3rBTqgYJ5woRlIQLkcI1QI-Mqk&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=19yMp5sJn3__ozaV3dFQ5LpaTLkEuCLs&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1w9jpQR59qCAqH2M9EKloyOYcmAQRcsk&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 22, // no quitar
    latlng: [50.074130223390696, 14.439524713650846],
    nombre: "Praga",
    bandera: "CZ",
    foto: "Ciudades europeas/Praga/ancient-21808.jpg",
    fotos: [
      "Ciudades europeas/Praga/ancient-21808.jpg",
      "Ciudades europeas/Praga/oleh-morhun-8gEXxhCS0rE-unsplash.jpg",
      "Ciudades europeas/Praga/prague-4378356.jpg",
      "Ciudades europeas/Praga/william-zhang-6En4WYsNYXM-unsplash.jpg",
      "Ciudades europeas/Praga/architecture-5037095.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Praga Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1zHjSYQbW4CjObozco9UNRrXzHe_pQR4&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1v3LW8OtOe72zBv1ZT7MT_8uiU8nW6uo&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1zXm6oubj7ZqIY0-ukZ78WkiPwSl5z90&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1lt_xywMZst0wKWokaLHLp5mpU-6BRUA&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1kskd--kzAVfBT537i31dEdtKcjj67mg&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 23, // no quitar
    latlng: [52.52051211740013, 13.404107495620368],
    nombre: "Berlín",
    bandera: "DE",
    foto: "Ciudades europeas/Berlin/florian-wehde-uFGi0_YciE0-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Berlin/florian-wehde-uFGi0_YciE0-unsplash.jpg",
      "Ciudades europeas/Berlin/berlin-4788732.jpg",
      "Ciudades europeas/Berlin/graffiti-745071.jpg",
      "Ciudades europeas/Berlin/s-widua-iPOZf3tQfHA-unsplash.jpg",
      "Ciudades europeas/Berlin/santiago-flores-q9NSsagKwjs-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Berlín Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1x5tjTl9UeAYdRUiPrH-4jenZJMeBfO0&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1WkkSjOB48rGV0a5UWcmKi9pnydvqqaw&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1M_CIB1O8-3p1Y84du3PiVWGQhxB21wM&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1dbXjqewF9ZmC7JFCdxPRwyCZ6B4QD4U&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1hVhh9NGPJao5IC5NfP7tBfgNUkcg7jU&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 24, // no quitar
    latlng: [64.14779865646733, -21.949950728011697],
    nombre: "Reikiavik",
    bandera: "IS",
    foto: "Ciudades europeas/Reikiavik/yanshu-lee-N7C8NlKZxAI-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Reikiavik/yanshu-lee-N7C8NlKZxAI-unsplash.jpg",
      "Ciudades europeas/Reikiavik/pexels-lyn-ong-6675921.jpg",
      "Ciudades europeas/Reikiavik/frank-denney-vCqclUnCg_8-unsplash.jpg",
      "Ciudades europeas/Reikiavik/boris-hadjur-vRzs-4oLi-g-unsplash.jpg",
      "Ciudades europeas/Reikiavik/aiden-patrissi-3QL4OjNY3qU-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Reikiavik Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=19R7M0EVFc2e7Uafov8l5IDbD_CyMa9I&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1vamnp5gWt9VpU9FiwfY3oHYHotIKvZ0&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1KGkUYs6Y4NXMTb40Uz4nlC0PMBAjiVY&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1-xxVsZdo_61hjmdbnZQtMPOKwh_3Ir8&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1F_XxQMcYYNMMguUroazSyioCC8RQWLw&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 25, // no quitar
    latlng: [41.00808664818219, 28.95989747122967],
    nombre: "Estambul",
    bandera: "TR",
    foto: "Ciudades europeas/Estambul/hussein-a-himmati-u_PuOVTTfOg-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Estambul/hussein-a-himmati-u_PuOVTTfOg-unsplash.jpg",
      "Ciudades europeas/Estambul/pexels-turgut-kepirli-15462790.jpg",
      "Ciudades europeas/Estambul/wei-pan-eBE3pEIZjbc-unsplash.jpg",
      "Ciudades europeas/Estambul/pexels-pavel-danilyuk-7405756.jpg",
      "Ciudades europeas/Estambul/pexels-yasir-gürbüz-13591994.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Estambul Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1zfuEZMEzqV-UPFxdSXOCbVxqrvUfzpg&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1iX-_zZsMZL0j5_j2Tk9W71cS4KvhM8g&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1xAPJQYhrcAzfcZZP4vAmt2WLdfodezY&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=14_uhKdfVkJpEd2pZtaLOo3vYcHdC960&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=15qRsrxSWvPEpt7JPqFWCFN9udlbTVPw&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 26, // no quitar
    latlng: [51.50660664228998, -0.1240927608852829],
    nombre: "Londres",
    bandera: "GB",
    foto: "Ciudades europeas/Londres/london-1335477.jpg",
    fotos: [
      "Ciudades europeas/Londres/london-1335477.jpg",
      "Ciudades europeas/Londres/benjamin-davies-Oja2ty_9ZLM-unsplash.jpg",
      "Ciudades europeas/Londres/9D659406-B8E9-49A5-8C21-C9B5216D77FA.jpg",
      "Ciudades europeas/Londres/jack-finnigan-Mxqvo8hhY1s-unsplash.jpg",
      "Ciudades europeas/Londres/uk-4822338.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Londres Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1l77sJHcwtAA8VeaLoagfirqDM8PWKZY&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=18TUdpVAzzqDlf4DzVT3ZgoYQLnMfKTU&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1jxyUYJ_sEZEOZNy_m36tVb1feAeZpvI&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=16fw5pWlU4hnU9DOTlG_As8ZF6UFpwT0&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1BVmJF3t5TtfGVIQC9m101cg_Q-BSlB8&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 27, // no quitar
    latlng: [48.85747835753212, 2.356303002337352],
    nombre: "París",
    bandera: "FR",
    foto: "Ciudades europeas/Paris/bastien-nvs-UkciZ75kqJ0-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Paris/bastien-nvs-UkciZ75kqJ0-unsplash.jpg",
      "Ciudades europeas/Paris/pexels-pixabay-2363.jpg",
      "Ciudades europeas/Paris/florian-wehde-milUxSbp4_A-unsplash.jpg",
      "Ciudades europeas/Paris/lola-delabays-YZjV7MeDQmg-unsplash.jpg",
      "Ciudades europeas/Paris/paris-2499022.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "París Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1vopYjvHUOzxtOP5N_6o1DCvZ8YnlxGk&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1w2fbKvYbLfIkWgaQbrV7qlMWJ7LI0vE&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1903_k8eW8MH6xwI2RAX-JJwmlHHqJlI&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1kfl_MxD5Hwt774lSybYVscFL8TgJmfc&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1UUY5D-YFpPBr7SgxHsyxhKSsc51ROVY&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 28, // no quitar
    latlng: [60.17276916543348, 24.929395915392902],
    nombre: "Helsinki",
    bandera: "FI",
    foto: "Ciudades europeas/Helsinki/jaakko-kemppainen-For4352Fgwg-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Helsinki/jaakko-kemppainen-For4352Fgwg-unsplash.jpg",
      "Ciudades europeas/Helsinki/cityscape-5963374.jpg",
      "Ciudades europeas/Helsinki/tom-brunberg-ohD7kQxykho-unsplash.jpg",
      "Ciudades europeas/Helsinki/tapio-haaja-0WDOJXeU3fQ-unsplash.jpg",
      "Ciudades europeas/Helsinki/saad-chaudhry-aSG0vBRWnoM-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Helsinki Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1rfllKo5mrrs_qK5lw7JXiw7pp9TJwT4&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1RTO5BNgaS3SaNtxud639AtVZ0Kh1bEM&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1fWJRKcuuHtHBLBV2Ioqde3pb1ua4L7k&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1qlKFwdjNJMJ95IDRbIERtGDW3SScYho&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1d6NmpnfqLkfh4sEJGg5wbvJksh9_RKE&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 29, // no quitar
    latlng: [59.3309705737686, 18.062384613814903],
    nombre: "Estocolmo",
    bandera: "SE",
    foto: "Ciudades europeas/Estocolmo/raphael-andres-3cwvFD-YPtk-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Estocolmo/raphael-andres-3cwvFD-YPtk-unsplash.jpg",
      "Ciudades europeas/Estocolmo/jon-flobrant-bK2nx6uEJnc-unsplash.jpg",
      "Ciudades europeas/Estocolmo/alexandre-van-thuan-DPwd8uWFb0k-unsplash.jpg",
      "Ciudades europeas/Estocolmo/stockholm-436232.jpg",
      "Ciudades europeas/Estocolmo/axel-antas-bergkvist-oSGXn0TaKiU-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Estocolmo Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1zfuEZMEzqV-UPFxdSXOCbVxqrvUfzpg&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1iX-_zZsMZL0j5_j2Tk9W71cS4KvhM8g&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1xAPJQYhrcAzfcZZP4vAmt2WLdfodezY&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=14_uhKdfVkJpEd2pZtaLOo3vYcHdC960&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=15qRsrxSWvPEpt7JPqFWCFN9udlbTVPw&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 30, // no quitar
    latlng: [59.91237436999485, 10.753525473250983],
    nombre: "Oslo",
    bandera: "NO",
    foto: "Ciudades europeas/Oslo/christoffer-engstrom-tjguVu0GoEM-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Oslo/christoffer-engstrom-tjguVu0GoEM-unsplash.jpg",
      "Ciudades europeas/Oslo/arvid-malde-3VCrw7nHH4A-unsplash.jpg",
      "Ciudades europeas/Oslo/janko-ferlic-McFeZrxySIo-unsplash.jpg",
      "Ciudades europeas/Oslo/pexels-boris-k-5683079.jpg",
      "Ciudades europeas/Oslo/oslo-957743.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Oslo Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=13zejG1uvqHc_Ji032ZlSzf4G7dx_NeI&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1RzQZiq44oZ1DfmY0Qy7g_25kHZbbv9k&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1aCfTlxDRL0bJ0tRtwSQxg3JlAy9i9gM&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=13eO_HRDnUFlqXe1-cuR2xARL0jKMbF0&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1weguLSbK4vo-c9utIIM39JYBcXeGU60&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 31, // no quitar
    latlng: [53.348223567499126, -6.254636728062814],
    nombre: "Dublín",
    bandera: "IE",
    foto: "Ciudades europeas/Dublin/pexels-luciann-photography-3566191.jpg",
    fotos: [
      "Ciudades europeas/Dublin/pexels-luciann-photography-3566191.jpg",
      "Ciudades europeas/Dublin/alex-block-PdDBTrkGYLo-unsplash.jpg",
      "Ciudades europeas/Dublin/christopher-zapf-vB5oHQr70b4-unsplash.jpg",
      "Ciudades europeas/Dublin/gregory-dalleau-KT4dOfvtZSg-unsplash.jpg",
      "Ciudades europeas/Dublin/k-mitch-hodge-DG7hMd-uneI-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Dublín Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1tVXk5sCK1yfdKWOqjo_Qu_kmTD3ECMY&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1t4SoBVf1mfL9IqQb0Wht61uzA3rOj2Q&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1q_b5jKT-1Jl9LFSdwFKLVAaasW5I_Qk&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1WImKBFLulxn-a6eurR13RYdjF_dv7_Q&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1SyMRqXVMKNIT7W_j5QROVYjwxj0e8UI&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 32, // no quitar
    latlng: [40.41382529724229, -3.7104434622968525],
    nombre: "Madrid",
    bandera: "ES",
    foto: "Ciudades europeas/Madrid/florian-wehde-WBGjg0DsO_g-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Madrid/florian-wehde-WBGjg0DsO_g-unsplash.jpg",
      "Ciudades europeas/Madrid/tania-fernandez-zVdf2Hmrcxw-unsplash.jpg",
      "Ciudades europeas/Madrid/emilio-garcia-l0TmmYg4pNA-unsplash.jpg",
      "Ciudades europeas/Madrid/pexels-jo-kassis-5005426.jpg",
      "Ciudades europeas/Madrid/paula-carrasco-R30nnrR8j_o-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Madrid Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=119j9PVL9dGwC4ZAdoQEnIUkS1HvkH1I&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1au2rgaIabM5uzqENziHmiMgRdYz7ffI&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ntYwt1sRzJpErt8cVWNUfOqzlTbKZ_o&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Bj6-xAeik49Cf04YAgb2em6DVLqrw88&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1dISGWFmtxPZjjB-TnXoujYvMECcw0KU&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 33, // no quitar
    latlng: [41.387495327619696, 2.1752061021581013],
    nombre: "Barcelona",
    bandera: "ES",
    foto: "Ciudades europeas/Barcelona/F95D2D4C-D169-4BFC-BDE6-9F06706797FC.JPG",
    fotos: [
      "Ciudades europeas/Barcelona/F95D2D4C-D169-4BFC-BDE6-9F06706797FC.JPG",
      "Ciudades europeas/Barcelona/8E78AAC3-2C4F-4F68-ACC7-E65945A7CF35.JPG",
      "Ciudades europeas/Barcelona/284A379C-2564-42E8-9961-84AD8D054D38.JPG",
      "Ciudades europeas/Barcelona/67BCDB0A-9EB2-4D59-BA2E-D56DD6327119.JPG",
      "Ciudades europeas/Barcelona/IMG_5868.JPG",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Barcelona Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YgNpC-dcGHmrnIUWIQ-kvvdfGKbgbdo&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1tgrYJuOCLRYsjz2ZkZYGb2LDpMIdlK0&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YhK7FYY61qUypOOnTepNXPzFU4nrSdQ&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1O2m-C6r95-QvcWUuXnjmFbiqGu90i8w&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1t8ZUIcZdr-uW3j3x3iq6PbgrWhLMymo&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 34, // no quitar
    latlng: [52.36371136063938, 4.90327693524584],
    nombre: "Amsterdam",
    bandera: "NL",
    foto: "Ciudades europeas/Amsterdam/max-van-den-oetelaar--e4vLFZV9QM-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Amsterdam/max-van-den-oetelaar--e4vLFZV9QM-unsplash.jpg",
      "Ciudades europeas/Amsterdam/piotr-chrobot-M4EcjtmE01k-unsplash.jpg",
      "Ciudades europeas/Amsterdam/jace-afsoon-VEXIwDcY1gw-unsplash (1).jpg",
      "Ciudades europeas/Amsterdam/catalina-fedorova-RRl_m_fQzNY-unsplash.jpg",
      "Ciudades europeas/Amsterdam/andrew-slifkin-bDdMklujkD4-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Amsterdam Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Bhc4lfaR8qptbh4YjFhjG66ecj5qMvw&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1-LScBfYqZB6pUo-KjzIf7aDwm67HKko&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1lK4Zxo5nHrduTxRe2uqzTGOjMTzVinY&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1hMaP5h7hy9RIxtRsAFVojFp3x8TSH1s&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1NX7vAMf8_EZxByysZkr1XHOZKefiejo&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 35, // no quitar
    latlng: [37.985690888298066, 23.7264942139475],
    nombre: "Atenas",
    bandera: "GR",
    foto: "Ciudades europeas/Atenas/aussieactive-sHDQCcOTD4Y-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Atenas/aussieactive-sHDQCcOTD4Y-unsplash.jpg",
      "Ciudades europeas/Atenas/florian-giorgio-Ly1DHTvjSvs-unsplash.jpg",
      "Ciudades europeas/Atenas/rafael-hoyos-weht-UHcnIzIFZw8-unsplash.jpg",
      "Ciudades europeas/Atenas/greece-998489.jpg",
      "Ciudades europeas/Atenas/constantinos-kollias-yqBvJJ8jGBQ-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Atenas Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1KdW5Ivg6FPgrtbxCkxr-sbb005_sEAY&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1w5NGYRpDFHjtCDSFKGjPbIP-EtymjBk&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1OGzTABDGwf3g7kmZ_PZWOyFuH0EkDzs&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1RXC6MmKEuz7NT52xSLDhU37k5uOIlos&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1vEyf_5xlghjtUmIyPunmsPCWaTMfS1M&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 36, // no quitar
    latlng: [50.84686642195245, 4.355846050641385],
    nombre: "Bruselas",
    bandera: "BE",
    foto: "Ciudades europeas/Bruselas/fisnik-murtezi-HwnYbnu5XHc-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Bruselas/fisnik-murtezi-HwnYbnu5XHc-unsplash.jpg",
      "Ciudades europeas/Bruselas/belgium-3590638.jpg",
      "Ciudades europeas/Bruselas/brussels-218132_1920.jpg",
      "Ciudades europeas/Bruselas/robby-mccullough-ieBE8fYsiJI-unsplash.jpg",
      "Ciudades europeas/Bruselas/bernardo-lorena-ponte-Gd1rangJ3FY-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpgg",
        titulo: "Bruselas Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1N_rWR3Qc7KiC2BzGjCn6-R-3f56Y6yg&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpgg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Xi6kQmmCR9gr-0oAbhX9jVqjsknbYzo&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1XjgzNqKDGkEUCTPMWiJb7PFkoq9apMA&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1e2ISwOF5W5gUO2usNYS9HDFuZcWT02U&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=14qE4zDzeqOiQhoFuMUCW8ZYHRdD_8Ew&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 37, // no quitar
    latlng: [44.42548204844487, 26.10286996811889],
    nombre: "Bucarest",
    bandera: "RO",
    foto: "Ciudades europeas/Bucarest/ashley-knedler-UdQ4SdV9UE8-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Bucarest/ashley-knedler-UdQ4SdV9UE8-unsplash.jpg",
      "Ciudades europeas/Bucarest/hari-nandakumar-mLOCUas8xfs-unsplash.jpg",
      "Ciudades europeas/Bucarest/bucharest-2711816.jpg",
      "Ciudades europeas/Bucarest/pexels-eduard-balan-775482.jpg",
      "Ciudades europeas/Bucarest/mathias-konrath-Y7BG6yO9Q9o-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Bucarest Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1weS26nkOkr6C7qYRzJuKFuv9Vz_SJDg&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YnYfyB-DY1cDgN5dGKYREcd_Tb7ArLE&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1RN6feRD93AQVQOFEGqEnmUC3NV3KsAA&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1_ZlBUkj0O2HbZxROl4Ci6yXePflxVyE&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1hgb3IBsNsTAkqvK62s0byrsfSc6tSF0&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 38, // no quitar
    latlng: [47.49412016848895, 19.054078373281495],
    nombre: "Budapest",
    bandera: "HU",
    foto: "Ciudades europeas/Budapest/gabriel-miklos-LiE-pn2fJ5I-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Budapest/gabriel-miklos-LiE-pn2fJ5I-unsplash.jpg",
      "Ciudades europeas/Budapest/bence-balla-schottner-VPETME4zVWM-unsplash.jpg",
      "Ciudades europeas/Budapest/bridge-111326.jpg",
      "Ciudades europeas/Budapest/budapest-495752.jpg",
      "Ciudades europeas/Budapest/linda-gerbec-YJ9ujIUvMtg-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Budapest Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=10PFJhpBMCuaDQbodbzEuXhO3AElRzTQ&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ntD1CPnXXTioRpv0NgXO893pOE32toE&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=19SJD4QVtH3fLI2D_ZHj1hAD39-j0ydw&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1UX_mNkEMKckqHYETNzNsq6ydyMkOVWw&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1wcYHfbCFBgDCKuogIwMi6KEnZTC-9lM&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 39, // no quitar
    latlng: [55.67815708865146, 12.58626487733496],
    nombre: "Copenhaguen",
    bandera: "DK",
    foto: "Ciudades europeas/Copenhaguen/copenhagen-7150716.jpg",
    fotos: [
      "Ciudades europeas/Copenhaguen/copenhagen-7150716.jpg",
      "Ciudades europeas/Copenhaguen/shane-rounce-3nTPvdIZG_k-unsplash.jpg",
      "Ciudades europeas/Copenhaguen/ange-loron-8__e9QHVYOA-unsplash.jpg",
      "Ciudades europeas/Copenhaguen/brian-kyed-wCP9Mk0iisU-unsplash.jpg",
      "Ciudades europeas/Copenhaguen/peter-lloyd-yGFdovJATMc-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Copenhaguen Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1oMCyCmJ5RWLBV8ct6i42vqWA7mosJF8&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ys0-smi2A6F8KkWvJjwpKps9Buu9Vhs&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1sj3FkEYGOTI9F48NRh7kgyeOy6ZHv6E&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=14AQDe-ugj_jmLLrhvFypNyQ4r2i47vA&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Hy81iwNrllCeFu71xqXOeZChKEdy4a0&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 40, // no quitar
    latlng: [50.06167093163925, 19.939635066780365],
    nombre: "Cracovia",
    bandera: "PL",
    foto: "Ciudades europeas/Cracovia/krakow-4439925.jpg",
    fotos: [
      "Ciudades europeas/Cracovia/krakow-4439925.jpg",
      "Ciudades europeas/Cracovia/auschwitz-1137808.jpg",
      "Ciudades europeas/Cracovia/kevin-perez-camacho-t-SSPRyqo7c-unsplash.jpg",
      "Ciudades europeas/Cracovia/main-market-4981934.jpg",
      "Ciudades europeas/Cracovia/ranurte-s-m4adRh7-M-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Cracovia Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=10C54MUsrE3rr0B2_jKbEyumEThdwHaw&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1UoGmlEXRP57dpxCRooovzxDRDS5kR4A&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1-TG1xurU_LQN6KXoifUi03kZvNez64s&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1M8jwxt2bAJWEaXRQw-L3BsMqmur2-GQ&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1C6aUlVtN4cjzonph9f0wMfAD6Jt3WdA&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 41, // no quitar
    latlng: [55.95272331377012, -3.1916073278937005],
    nombre: "Edimburgo",
    bandera: "GB",
    foto: "Ciudades europeas/Edimburgo/pexels-carsten-ruthemann-7813912.jpg",
    fotos: [
      "Ciudades europeas/Edimburgo/pexels-carsten-ruthemann-7813912.jpg",
      "Ciudades europeas/Edimburgo/pexels-gever-15079126.jpg",
      "Ciudades europeas/Edimburgo/pexels-vlad-vasnetsov-12449389.jpg",
      "Ciudades europeas/Edimburgo/zhanhui-li-kkrTxlrgE5c-unsplash.jpg",
      "Ciudades europeas/Edimburgo/jorg-angeli-S56zN8cV5fk-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Edimburgo Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=13CSlDOpYRBFh7vxXdolG8oSBQi4d1XI&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1hsP2-RKj99d5A4rgSTz2z6_eIrsERyg&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1LxwRQF0BHaHBg54be8qeYMBtu_ap0NE&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1_7EFAu3d1ZxYto455c9eIGWYpXGQyzk&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Bken_CHR4kQd1p8TKPaTUk49FgRDWXY&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 42, // no quitar
    latlng: [43.770268670413444, 11.259848795154818],
    nombre: "Florencia",
    bandera: "IT",
    foto: "Ciudades europeas/Florencia/heidi-kaden-xWZa0GYCMbo-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Florencia/heidi-kaden-xWZa0GYCMbo-unsplash.jpg",
      "Ciudades europeas/Florencia/sunset-961119.jpg",
      "Ciudades europeas/Florencia/cathedral-5881418.jpg",
      "Ciudades europeas/Florencia/pexels-oleksandra-zelena-16054007.jpg",
      "Ciudades europeas/Florencia/sebastian-b-oK0mW8MKUCI-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Florencia Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1rhw1X93R-vY7lfqEZ_j1-i3InXdkGZE&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1BpK4_GV5r8Sow-XVGHidmcDxYp76fns&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1H50qe1lAb7UTUEgk-nPouTRzy9czx4w&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1WNejxDMgFJGu4IhD4XYULZPyToni7hQ&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=17StBIhxOGP3cjD0nTR5YzIlLPSnCWzg&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 43, // no quitar
    latlng: [55.861546617933854, -4.260616963499968],
    nombre: "Glasgow",
    bandera: "GB",
    foto: "Ciudades europeas/Glasgow/glasgow-4746491.jpg",
    fotos: [
      "Ciudades europeas/Glasgow/glasgow-4746491.jpg",
      "Ciudades europeas/Glasgow/jason-briscoe-jAE0R8jVOAA-unsplash.jpg",
      "Ciudades europeas/Glasgow/tag-2810648.jpg",
      "Ciudades europeas/Glasgow/adam-marikar-7vJX8cIgLao-unsplash.jpg",
      "Ciudades europeas/Glasgow/johnny-briggs-cJ-RyUD2ayc-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Glasgow Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=166pFLOltiFIvde0gSOZ03lycPku1d6s&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1osuuYBcIeoHMcfl7MFCODjFH1_6coTQ&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=17zQjjDlBaAkMMnDVblyx0GJouxxRx_Y&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1J3HmW2Tl3msAcOMuvmVy3F4ISrJ5F14&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1uVde2Pg2FFspHxiiZ-tGFJ8TY6tC8fw&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 44, // no quitar
    latlng: [38.72191255224206, -9.140979160973858],
    nombre: "Lisboa",
    bandera: "PT",
    foto: "Ciudades europeas/Lisboa/IMG_5881.JPG",
    fotos: [
      "Ciudades europeas/Lisboa/IMG_5881.JPG",
      "Ciudades europeas/Lisboa/IMG_5883.JPG",
      "Ciudades europeas/Lisboa/IMG_5877.JPG",
      "Ciudades europeas/Lisboa/IMG_5874.JPG",
      "Ciudades europeas/Lisboa/B8B1D00C-FFE4-4C91-88A2-0124925B227B.JPG",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Lsiboa Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=131BnycrVT7FKSEb2lEy9cTmngd9nlBQ&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1XrN72EJMjK-wWpULhqZ79VBcsn7YWIg&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ZOpmF249-7tiLviaFadypbSKp_K7Gbc&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=15CAkJ8XoF5j4gDpsoW3GeZgErTUUWCU&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1a933wHlvPr6JDBBGzqffBmJj19ZSDQY&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 45, // no quitar
    latlng: [53.40636516396573, -2.9847295276512327],
    nombre: "Liverpool",
    bandera: "GB",
    foto: "Ciudades europeas/Liverpool/bobbie-m-bYjfI33nK6Y-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Liverpool/bobbie-m-bYjfI33nK6Y-unsplash.jpg",
      "Ciudades europeas/Liverpool/ryan-warburton-i_DIg4xWlT8-unsplash.jpg",
      "Ciudades europeas/Liverpool/neil-martin-nFo5hbJTM8A-unsplash.jpg",
      "Ciudades europeas/Liverpool/neil-martin-svxxuYv-OeE-unsplash.jpg",
      "Ciudades europeas/Liverpool/liverpool-2828383.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Liverpool Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1qjQuQoU1d1CzmQKT0jOE4Yvq6NcDmcI&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1vJeYdY-pnC-MMVu8gGKJTywq1eXPBrI&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1pAfg4XPff6hSMEg8YbqhHr1nad0rxjI&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ZW-3qg9xG7k4a8Gk0yQ8tVp5-KfvR98&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1-w-1q6c_wsCrf215gPQX9lkWtTmvXns&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 46, // no quitar
    latlng: [53.47998956496657, -2.2440637851993093],
    nombre: "Manchester",
    bandera: "GB",
    foto: "Ciudades europeas/Manchester/pexels-nathan-j-hilton-5212814.jpg",
    fotos: [
      "Ciudades europeas/Manchester/pexels-nathan-j-hilton-5212814.jpg",
      "Ciudades europeas/Manchester/aswin-mahesh-4_LGBLe6VZA-unsplash.jpg",
      "Ciudades europeas/Manchester/manchester-cathedral-7770326.jpg",
      "Ciudades europeas/Manchester/manchester-united-1656122.jpg",
      "Ciudades europeas/Manchester/jack-finnigan-GYjQaABSlVI-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Manchester Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1EVc4SXQiLnAoFtzEkLvo-ovA6LXCMgM&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1NDP0L1E73vR9-uqrhYT4rItBaUeuij4&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1rh69NoW4OBKebxsa44RCZ3rjc6nq91s&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1dHQ-wcKqe7FceQCybpMwoxeUBfJ_Y2A&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1LyU1cJYnzVUFQkWbePjg69fmsMtcfyo&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 47, // no quitar
    latlng: [45.46825051957498, 9.194209932142558],
    nombre: "Milán",
    bandera: "IT",
    foto: "Ciudades europeas/Milan/ouael-ben-salah-0xe2FGo7Vc0-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Milan/ouael-ben-salah-0xe2FGo7Vc0-unsplash.jpg",
      "Ciudades europeas/Milan/pexels-pixabay-37079.jpg",
      "Ciudades europeas/Milan/alex-vasey-rnc4-Z4Zk8M-unsplash.jpg",
      "Ciudades europeas/Milan/architecture-5055472.jpg",
      "Ciudades europeas/Milan/fabio-oppini-5CewLjAyrTM-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Milán Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=17hMSaAFFf59TGVehcBA2jatRpyyo5R8&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1IG2ZIy39vkga785F-C8SCXcoycfYyBE&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1s9OyOJnSMFH9L7v_85m6IwPWIXUY8gQ&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1rEykd05ZuL1HYYZ8m3hdxr0vW5vGFzY&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YdI0e002idabpNqjX9R1VX1Vpe6oQtw&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 48, // no quitar
    latlng: [48.13374988241945, 11.591267336797596],
    nombre: "Múnich",
    bandera: "DE",
    foto: "Ciudades europeas/Munich/ian-kelsall-MEUvVqkU3QI-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Munich/ian-kelsall-MEUvVqkU3QI-unsplash.jpg",
      "Ciudades europeas/Munich/oktoberfest-819716.jpg",
      "Ciudades europeas/Munich/periklis-lolis-Uo1cYwElmeg-unsplash.jpg",
      "Ciudades europeas/Munich/louis-hansel-WCm4dFvZnMM-unsplash.jpg",
      "Ciudades europeas/Munich/sergey-mind-s8gh7IS9ulo-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Munich Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1_39QZFlFMU8z25B3OECf8V1fVL936xE&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=107BHDYoC8dt9H5VX-56yDy3Ptaztkk8&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1wq0AwBaz8-cDS7Z5RyRWzxxb01hxkO4&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1TNzwTPoev1u4TYyH0tZdFRRElDYVxTo&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1a1JrnWFf4LrenXlW6hSnF8Mh9iLaEnA&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 49, // no quitar
    latlng: [41.15695212475565, -8.635001840897395],
    nombre: "Oporto",
    bandera: "PT",
    foto: "Ciudades europeas/Oporto/F7921218-31B9-482E-A87A-CD81156DF707.JPG",
    fotos: [
      "Ciudades europeas/Oporto/F7921218-31B9-482E-A87A-CD81156DF707.JPG",
      "Ciudades europeas/Oporto/IMG_5916.JPG",
      "Ciudades europeas/Oporto/IMG_5917.JPG",
      "Ciudades europeas/Oporto/IMG_5906.JPG",
      "Ciudades europeas/Oporto/tiles-521376.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Oporto Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ovthqQa5qPLO141e7mIz2AyY2KJSyMk&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1OkBivrJREMAWCexr7ibVpxfhnluUXeg&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1y6trrvCtWo6s_VXEXQ-1iS3lb59I7uQ&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1p-qCTf-A0HSsGP0reDV8L9C28C1QXoc&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1WVbaU3qugYWV4PhqP5xOkL1LbdZoB1o&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 50, // no quitar
    latlng: [42.70241170578845, 23.319132658877958],
    nombre: "Sofía",
    bandera: "BG",
    foto: "Ciudades europeas/Sofia/alexandr-bormotin-UPoSvgmFKmo-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Sofia/alexandr-bormotin-UPoSvgmFKmo-unsplash.jpg",
      "Ciudades europeas/Sofia/sofia-4092787.jpg",
      "Ciudades europeas/Sofia/sofia-2092000.jpg",
      "Ciudades europeas/Sofia/bulgaria-2790183.jpg",
      "Ciudades europeas/Sofia/nadezhda-katsarska-1Q5tFFkxz2o-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Sofía Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=19G6YxgUZCSvv9wXKWDEisjvcVDYlmus&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1oVLgRNoJ7h3zjV2kEhv5wblcdKadRog&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=12I2Xm73nV7cI6X8nAUPOemGwWz-sv_o&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1b2DrC8QssYX4wVmHs1R-6PnPNLLMAew&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=14wSIy7C-1WpKopy5m7dWbeILqSuscCU&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 51, // no quitar
    latlng: [45.4401212060075, 12.331707344454255],
    nombre: "Venecia",
    bandera: "IT",
    foto: "Ciudades europeas/Venecia/paul-szewczyk-DXGzmhANx68-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Venecia/paul-szewczyk-DXGzmhANx68-unsplash.jpg",
      "Ciudades europeas/Venecia/venice-2085864.jpg",
      "Ciudades europeas/Venecia/venice-2647436.jpg",
      "Ciudades europeas/Venecia/lopez-robin-XZPHNxsWSDU-unsplash.jpg",
      "Ciudades europeas/Venecia/kit-suman-5mcnzeSHFvE-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Venecia Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1VZSubGTjflpvA_rv2zrj3d7K6eADs5c&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1salKKqVnizBBZQwiVQozl9uDk6wJ7uk&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1SPfIJSjGBEdD2xDByMz35kqk5yxiFO4&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=10HMTxE9dv8FpSURPC_tjZP7OoDsrtLQ&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1X1LBEm8unB1FLINfgVlY0WntwVcjfEI&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 52, // no quitar
    latlng: [48.204585264263734, 16.376619838928654],
    nombre: "Viena",
    bandera: "AT",
    foto: "Ciudades europeas/Viena/jacek-dylag-IiQXLbTTQCw-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Viena/jacek-dylag-IiQXLbTTQCw-unsplash.jpg",
      "Ciudades europeas/Viena/arpad-czapp-9WWwJqd-YAQ-unsplash.jpg",
      "Ciudades europeas/Viena/datingjungle-5VcOPcYFch8-unsplash.jpg",
      "Ciudades europeas/Viena/vienna-1303429.jpg",
      "Ciudades europeas/Viena/sami-ullah-9_P3_-sT--4-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Viena Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1fjcbu2B3R-dTQHGwyqnWbcB0MbQyRsY&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1rBZ_cNbn0dLZF9134FiOKH8fQ8CjhyM&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1JOdEU6_Lk9-YSJbcXamceckNE7Gyof0&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1YRkP7451V-pyEutGCu7B7i7yYnhNKXM&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1ZxY4P0HWPYXTsAPxC22RjAJ46hXaBwA&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 53, // no quitar
    latlng: [52.22864946621669, 21.003509496548045],
    nombre: "Varsovia",
    bandera: "PL",
    foto: "Ciudades europeas/Varsovia/kamil-gliwinski-xcPw1-5OHTk-unsplash.jpg",
    fotos: [
      "Ciudades europeas/Varsovia/kamil-gliwinski-xcPw1-5OHTk-unsplash.jpg",
      "Ciudades europeas/Varsovia/elijah-g-oOHHxQ65dFE-unsplash.jpg",
      "Ciudades europeas/Varsovia/andriej-szypilow-FUxOSpf1uK8-unsplash.jpg",
      "Ciudades europeas/Varsovia/kris-cros-yD90_obqQ1Y-unsplash.jpg",
      "Ciudades europeas/Varsovia/pexels-skitterphoto-10676.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Varsovia Completo",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1Paa3meTWBfqXf6n1lxbZldO6RD_pRtc&ehbc=2E312F",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1eeY-fLAeFVtY1Dat1xAbhVR5WKKb1K4&ehbc=2E312F",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1fJmeIgLm9u9QtbEjQcuHBcF8DcDxwfE&ehbc=2E312F",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1u7f1z0QjK486xSHjqyd86rZ8xXnC9xI&ehbc=2E312F",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://www.google.com/maps/d/u/2/embed?mid=1NhPPuwuQKSMyz3ajUHRbm06SCCfeKwM&ehbc=2E312F",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    id: 54, // no quitar
    latlng: [35.681201040137665, 139.76920175514647],
    nombre: "Japón",
    bandera: "JP",
    foto: "Sudeste asiático/Japón/nicki-eliza-schinow-BjJP2TN8WoI-unsplash.jpg",
    fotos: [
      "Sudeste asiático/Japón/nicki-eliza-schinow-BjJP2TN8WoI-unsplash.jpg",
      "Sudeste asiático/Japón/lin-mei-NYyCqdBOKwc-unsplash.jpg",
      "Sudeste asiático/Japón/alex-knight-5-GNa303REg-unsplash.jpg",
      "Sudeste asiático/Japón/jj-ying-9Qwbfa_RM94-unsplash.jpg",
      "Sudeste asiático/Japón/georgia-z-0StN_tFWOhc-unsplash.jpg",
    ],
    mapas: [
      {
        id: 1,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Japón Completo",
        link: "https://google.com",
      },
      {
        id: 2,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://google.com",
      },
      {
        id: 3,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://google.com",
      },
      {
        id: 4,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://google.com",
      },
      {
        id: 5,
        foto: "wayfy/Logo 1.jpg",
        titulo: "Descargar",
        link: "https://google.com",
      },
    ],

    destacada: [
      {
        id: 1,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Generalidades",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 2,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Itinerarios",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 3,
        titulo: "Consejos y recomendaciones",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 4,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Apps fundamentales",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 5,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Gastronomia",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 6,
        titulo: "Climatoligía",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 7,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 7",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
      {
        id: 8,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 8",
        texto: `Texto de la info destacada de acordion numero 2`,
      },
      {
        id: 9,
        titulo: "Info 9",
        foto: "wayfy/Logo 2.jpg",
        texto: `Texto de la info destacada de acordion numero 3`,
      },
      {
        id: 10,
        foto: "wayfy/Logo 2.jpg",
        titulo: "Info 10",
        texto: `Texto de la info destacada de acordion numero 1`,
      },
    ],
    ciudades: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    alojamiento: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    apps: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
    otros: [
      {
        id: 1,
        ciudad: "Madrid",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 2,
        ciudad: "Barcelona",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 3,
        ciudad: "Málaga",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 4,
        ciudad: "Valencia",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
      {
        id: 5,
        ciudad: "Bilbao",
        foto: "wayfy/Logo 2.jpg",
        actividades: [
          {
            id: 1,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 2,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 3,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 3",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 4,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 1",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
          {
            id: 5,
            foto: "wayfy/Logo 2.jpg",
            logo: "wayfy/Logo 2.jpg",
            titulo: "Actividad 2",
            descripcion: `descripcion de la actividad numero 1`,
            link: "https://google.com",
          },
        ],
      },
    ],
  },
];
